define('WolfSellers_Checkout/js/validate-extra-fields',[
    'jquery',
    'jquery/validate',
    'mage/mage'
], function($){
    'use strict';

    return function (target) {
        $.validator.addMethod(
            "validate-latitude",
            function(value, element) {
                if ( value.length == 0 ){ return false; }
                return isFinite(value) && Math.abs(value) <= 90;
            },
            $.mage.__("Ingrese latitud válida.")
        );

        $.validator.addMethod(
            "validate-longitude",
            function(value, element) {
                if ( value.length == 0 ){ return false; }
                return isFinite(value) && Math.abs(value) <= 180
            },
            $.mage.__("Ingrese longitud válida.")
        );

        return target;
    };
});
